import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Developing smart contracts for Ethereum involves a bevy of off-chain tools used for producing and testing bytecode
that runs on the `}<a parentName="p" {...{
        "href": "https://eth.wiki/en/concepts/evm/ethereum-virtual-machine-(evm)-awesome-list"
      }}>{`Ethereum Virtual Machine (EVM)`}</a>{`.
Some tools also include workflows for deploying this bytecode to the Ethereum network and testnets.
There are many options for these tools. This guide walks you through writing and testing a simple smart contract that
interacts with the Materia Protocol using one specific set of tools (`}<inlineCode parentName="p">{`truffle`}</inlineCode>{` + `}<inlineCode parentName="p">{`npm`}</inlineCode>{` + `}<inlineCode parentName="p">{`mocha`}</inlineCode>{`).`}</p>
    <h2 {...{
      "id": "requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#requirements",
        "aria-label": "requirements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Requirements`}</h2>
    <p>{`To follow this guide, you must have the following installed:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nodejs.org/en/"
        }}>{`nodejs >= v12.x & npm >= 6.x`}</a></li>
    </ul>
    <h2 {...{
      "id": "bootstrapping-a-project",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bootstrapping-a-project",
        "aria-label": "bootstrapping a project permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bootstrapping a project`}</h2>
    <p>{`You can start from scratch, but it’s easier to use a tool like `}<inlineCode parentName="p">{`truffle`}</inlineCode>{` to bootstrap an empty project.
Create an empty directory and run `}<inlineCode parentName="p">{`npx truffle init`}</inlineCode>{` inside that directory to unbox the default
`}<a parentName="p" {...{
        "href": "https://www.trufflesuite.com/boxes"
      }}>{`Truffle box`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "script",
        "script": true
      }}>{`mkdir demo
cd demo
npx truffle init
`}</code></pre>
    <h2 {...{
      "id": "setting-up-npm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-up-npm",
        "aria-label": "setting up npm permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up npm`}</h2>
    <p>{`In order to reference the Materia contracts, you should use the npm artifacts we deploy containing the core and
periphery smart contracts and interfaces. To add npm dependencies, we first initialize the npm package.
We can run `}<inlineCode parentName="p">{`npm init`}</inlineCode>{` in the same directory to create a `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file. You can accept all the defaults and
change it later.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "script",
        "script": true
      }}>{`npm init
`}</code></pre>
    <h2 {...{
      "id": "adding-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-dependencies",
        "aria-label": "adding dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding dependencies`}</h2>
    <p>{`Now that we have an npm package, we can add our dependencies. Let’s add both the
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/@materia-dex/materia-contracts-core"
      }}><inlineCode parentName="a">{`@materia-dex/materia-contracts-core`}</inlineCode></a>{` and
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/@materia-dex/materia-contracts-proxy"
      }}><inlineCode parentName="a">{`@materia-dex/materia-contracts-proxy`}</inlineCode></a>{` packages.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "script",
        "script": true
      }}>{`npm i --save @materia-dex/materia-contracts-core
npm i --save @materia-dex/materia-contracts-proxy
`}</code></pre>
    <p>{`These packages include both the smart contract source code and the build artifacts.`}</p>
    <h2 {...{
      "id": "writing-our-contract",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#writing-our-contract",
        "aria-label": "writing our contract permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Writing our contract`}</h2>
    <p>{`We can now get started writing our example contract.
For writing Solidity, we recommend IntelliJ or VSCode with a solidity plugin, but you can use any text editor.
Let’s write a contract that returns the value of some amount of liquidity shares for a given token pair.
First create a couple of files:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "script",
        "script": true
      }}>{`mkdir contracts/interfaces
touch contracts/interfaces/ILiquidityValueCalculator.sol
touch contracts/LiquidityValueCalculator.sol
`}</code></pre>
    <p>{`This will be the interface of the contract we implement. Put it in `}<inlineCode parentName="p">{`contracts/interfaces/ILiquidityValueCalculator.sol`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`pragma solidity ^0.6.6;

interface ILiquidityValueCalculator {
    function computeLiquidityShareValue(uint liquidity, address tokenA, address tokenB) external returns (uint tokenAAmount, uint tokenBAmount);
}
`}</code></pre>
    <p>{`Now let’s start with the constructor. You need to know where the `}<inlineCode parentName="p">{`MateriaFactory`}</inlineCode>{` is deployed in order to compute the
address of the pair and look up the total supply of liquidity shares, plus the amounts for the reserves.
We can store this as an address passed to the constructor.`}</p>
    <p>{`The factory address is constant on mainnet and all testnets, so it may be tempting to make this value a constant in your contract,
but since we need to unit test the contract it should be an argument. You can use solidity immutables to save on gas
when accessing this variable.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`pragma solidity ^0.6.6;

import './interfaces/ILiquidityValueCalculator.sol';

contract LiquidityValueCalculator is ILiquidityValueCalculator {
    address public factory;
    constructor(address factory_) public {
        factory = factory_;
    }
}
`}</code></pre>
    <p>{`Now we need to be able to look up the total supply of liquidity for a pair, and its token balances.
Let’s put this in a separate function. To implement it, we must:`}</p>
    <ol>
      <li parentName="ol">{`Look up the pair address`}</li>
      <li parentName="ol">{`Get the reserves of the pair`}</li>
      <li parentName="ol">{`Get the total supply of the pair liquidity`}</li>
      <li parentName="ol">{`Sort the reserves in the order of tokenA, tokenB `}</li>
    </ol>
    <p>{`The `}<a parentName="p" {...{
        "href": "/docs/materia/smart-contracts/library/"
      }}><inlineCode parentName="a">{`MateriaLibrary`}</inlineCode></a>{` has some helpful methods for this.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`pragma solidity ^0.6.6;

import './interfaces/ILiquidityValueCalculator.sol';
import '@materia/materia-contracts-proxy/contracts/libraries/MateriaLibrary.sol';
import '@materia/materia-contracts-core/contracts/interfaces/IMateriaPair.sol';

contract LiquidityValueCalculator is ILiquidityValueCalculator {
    function pairInfo(address tokenA, address tokenB) internal view returns (uint reserveA, uint reserveB, uint totalSupply) {
        IMateriaPair pair = IMateriaPair(MateriaLibrary.pairFor(factory, tokenA, tokenB));
        totalSupply = pair.totalSupply();
        (uint reserves0, uint reserves1,) = pair.getReserves();
        (reserveA, reserveB) = tokenA == pair.token0() ? (reserves0, reserves1) : (reserves1, reserves0);
    } 
}
`}</code></pre>
    <p>{`Finally we just need to compute the share value. We will leave that as an exercise to the reader.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`pragma solidity ^0.6.6;

import './interfaces/ILiquidityValueCalculator.sol';
import '@materia/materia-contracts-proxy/contracts/libraries/MateriaLibrary.sol';
import '@materia/materia-contracts-core/contracts/interfaces/IMateriaPair.sol';

contract LiquidityValueCalculator is ILiquidityValueCalculator {
    address public factory;
    constructor(address factory_) public {
        factory = factory_;
    }

    function pairInfo(address tokenA, address tokenB) internal view returns (uint reserveA, uint reserveB, uint totalSupply) {
        IMateriaPair pair = IMateriaPair(MateriaLibrary.pairFor(factory, tokenA, tokenB));
        totalSupply = pair.totalSupply();
        (uint reserves0, uint reserves1,) = pair.getReserves();
        (reserveA, reserveB) = tokenA == pair.token0() ? (reserves0, reserves1) : (reserves1, reserves0);
    }
 
    function computeLiquidityShareValue(uint liquidity, address tokenA, address tokenB) external override returns (uint tokenAAmount, uint tokenBAmount) {
        revert('TODO');
    }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      